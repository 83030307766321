import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';
import * as Styled from './slpPagsTermsStyle';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

const SlpPagsTerms = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <Styled.HeaderH1>{children}</Styled.HeaderH1>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.LinkStyle
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node?.data?.uri)
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.LinkStyle>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Styled.MainContainer>
      {sectionData?.contentDetails && (
        <>
          {documentToReactComponents(
            JSON.parse(sectionData?.contentDetails?.raw),
            optionsMainStyle
          )}
        </>
      )}
      <div style={{ clear: 'both' }}></div>
    </Styled.MainContainer>
  );
};

export default SlpPagsTerms;
